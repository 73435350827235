<div class="saved-search-edit-form">

    <rpc-input class="saved-search-preset-input-wrap" [options]="nameControlOptions" [type]="'text'"
        [appearance]="'fill'" [label]="'SAVED_SEARCH_FORM.TITLES.SAVED_SEARCH_NAME'">
    </rpc-input>

    <rpc-select class="saved-search-preset-select-wrap" [appearance]="'fill'" [color]="'accent'"
        [readonly]="isMobile$ | async" [label]="'SAVED_SEARCH_FORM.TITLES.FOLDER'" [shouldTranslate]="false"
        [controlOptions]="folderControlOptions" (closed)="onCloseCreateFolderControl()" (click)="onFolderMobileClick()">

        <ng-container *ngIf="isCreateFolderFormVisible; else createFolderButton">

            <add-edit-folder-form class="saved-search-preset-folder-form" [name]="getNewFolderName()"
                (save)="onCreateFolderClicked($event)" (canceled)="onCloseCreateFolderControl()"
                [submitButtonText]="'SAVED_SEARCH_FORM.BUTTONS.CREATE'">
            </add-edit-folder-form>

        </ng-container>

        <div class="rpc-divider rpc-divider--horizontal saved-search-preset-folder-divider">
        </div>

    </rpc-select>

    <div *ngIf="isShowIncludePreviousMatchesCheckbox" class="saved-search-preset-checkbox-wrap">

        <rpc-checkbox class="saved-search-preset-checkbox" [label]="'SAVED_SEARCH_FORM.TITLES.PREVIOUSLY_MATCHED'"
            [(state)]="includePreviousMatches">
        </rpc-checkbox>

        <div class="saved-search-preset-checkbox-note">
            {{ 'SAVED_SEARCH_FORM.TITLES.NOTE' | translate }}.
        </div>

    </div>

    <mat-slide-toggle class="saved-search-preset-toggle" [labelPosition]="'after'" [checked]="shouldSearchNewMatches"
        (change)="onSearchNewMatchesChange($event.checked)">

        <span>{{ 'SAVED_SEARCH_FORM.TITLES.INCLUDE_IN_NEW_MATCHES' | translate }}</span>

    </mat-slide-toggle>

</div>

<ng-template #mobileFolderList>

    <div class="saved-search-option-wrap">

        <div class="saved-search-option-header">

            <div class="saved-search-option-header-title">
                {{'SAVED_SEARCH_FORM.TITLES.SELECT_FOLDER' | translate}}
            </div>

            <rpc-icon-button class="saved-search-option-header-close" [iconName]="'cross'"
                [title]="'SAVED_SEARCH_FORM.TITLES.CLOSE'" (clicked)="onCloseFolderSelection()">
            </rpc-icon-button>

            <ng-container *ngTemplateOutlet="createFolderButton">
            </ng-container>

            <div class="saved-search-option-header-divider">
            </div>

        </div>

        <div class="saved-search-option-body">

            <div class="saved-search-option-list">

                <ng-container *ngFor="let folder of folderControlOptions.options">

                    <rpc-button class="saved-search-option-folder"
                        [class.saved-search-option-folder--default]="folder.value === folderNotSelectedId"
                        [title]="folder.title" [shouldTranslate]="false" [iconName]="folder.iconName"
                        (clicked)="onFolderSelected(folder.value)">
                    </rpc-button>

                </ng-container>

            </div>

        </div>

    </div>

</ng-template>

<ng-template #createFolderButton>

    <rpc-button class="saved-search-preset-folder" [title]="'SAVED_SEARCH_FORM.TITLES.NEW_FOLDER'"
        [iconName]="'add-new'" (clicked)="onShowCreateFolderControl()">
    </rpc-button>

</ng-template>