<div class="rpc-saved-search-list" *ngLet="{
        savedSearchesInfo: savedSearchesInfo$ | async,
        activeSavedSearchId: activeSavedSearchId$ | async
        } as savedSearchesData" (swipeup)="onExpandPanel()" data-mc-options='{ "touchAction": "pan-y" }'>

    <div class="rpc-saved-search-list-header">

        <div class="rpc-saved-search-list-header-title-wrap">

            <div class="rpc-saved-search-list-header-title">
                {{'SAVED_SEARCH_LIST.TITLES.SAVED_SEARCHES' | translate}}
            </div>

            <rpc-icon-button class="rpc-saved-search-list-header-close" [iconName]="'cross'" (clicked)="onClose()"
                [title]="'SAVED_SEARCH_LIST.TITLES.CLOSE'">
            </rpc-icon-button>

        </div>

        <saved-search-option *ngIf="showCreateOption"
            [savedSearchName]="'SAVED_SEARCH_LIST.TITLES.SAVE_YOUR_SEARCH'| translate"
            [disabled]="isCriteriaEmpty$ | async" (clicked)="onCreateNewSavedSearch()">
        </saved-search-option>

    </div>

    <div class="rpc-saved-search-list-body">

        <ng-container *ngFor="let savedSearchInfo of savedSearchesData.savedSearchesInfo; let index = index;">

            <saved-search-info class="rpc-saved-search-list-item" [savedSearch]="savedSearchInfo"
                [isEditable]="savedSearchInfo.isEditable"
                [isActive]="savedSearchInfo.id === savedSearchesData.activeSavedSearchId"
                (clicked)="onSavedSearchSelected(savedSearchInfo.id)" (edit)="onEditSavedSearch(savedSearchInfo.id)">
            </saved-search-info>

            <div class="rpc-divider rpc-divider--horizontal rpc-saved-search-list-divider"
                *ngIf="index < (savedSearchesData.savedSearchesInfo.length - 1)">
            </div>

        </ng-container>

    </div>

</div>