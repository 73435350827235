import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { EffectsModule } from '@ngrx/effects';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRippleModule } from '@angular/material/core';
import { NgLetModule } from 'ng-let';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

import { RpcControlsModule } from '@core-controls/controls.module';
import { RpcDirectivesModule } from '@core-directives/directives.module';
import { RpcPipesModule } from '@core-pipes/pipes.module';
import { ServerMessageService } from '@core-services/server-message.service';
import { TranslationLoaderService } from '@core-services/translation-loader.service';
import { RpcIconModule } from '@core-utils/rpc-icon/rpc-icon.module';
import { AppointmentNotificationComponent } from '@notifications/components/appointment-notification/appointment-notification.component';
import { NotificationsComponent } from '@notifications/components/notifications/notifications.component';
import { locale as english } from '@notifications/i18n/en';
import { serverMessagesMapping } from '@notifications/server-message-mapping/server-message-mapping';
import { notificationsReducer } from '@notifications/store/reducers/notifications.reducer';
import { UsersModule } from '@users/users.module';
import { NotificationItemComponent } from './components/notification-item/notification-item.component';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { NotificationGroupComponent } from './components/notification-group/notification-group.component';
import { MediaModule } from '@media/media.module';
import { NotificationsEffects } from './store/effects/notifications.effects';
import { NotificationCheckboxComponent } from './components/notification-checkbox/notification-checkbox.component';
import { NotificationAddedEffects } from './store/effects/notification-added.effects';
import { PushNotificationsEffects } from './store/effects/push-notifications.effects';
import { CreateAppointmentNotificationEffects } from './store/effects/create-appointment-notification.effects';
import { CreateCommentNotificationEffects } from './store/effects/create-comment-notification.effects';
import { CreateFolderNotificationEffects } from './store/effects/create-folder-notification.effects';
import { CreateListingActivityNotificationEffects } from './store/effects/create-listing-activity-notification.effects';
import { CreateListingNotificationEffects } from './store/effects/create-listing-notification.effects';
import { CreateUserNotificationEffects } from './store/effects/create-user-notification.effects';
import { NewVersionDialogComponent } from './components/new-version-dialog/new-version-dialog.component';

@NgModule({
    declarations: [
        NotificationsComponent,
        AppointmentNotificationComponent,
        NotificationListComponent,
        NotificationItemComponent,
        NotificationGroupComponent,
        NotificationCheckboxComponent,
        NewVersionDialogComponent
    ],
    imports: [
        // Library Modules
        CommonModule,
        TranslateModule,

        MatSlideToggleModule,
        MatTabsModule,
        MatRippleModule,
        NgLetModule,

        // Application Modules
        RpcIconModule,
        RpcControlsModule,
        RpcDirectivesModule,
        RpcPipesModule,
        UsersModule,
        MediaModule,

        // Module Configuration
        StoreModule.forFeature('notifications', notificationsReducer),
        EffectsModule.forFeature([
            NotificationsEffects,
            NotificationAddedEffects,
            PushNotificationsEffects,
            CreateAppointmentNotificationEffects,
            CreateCommentNotificationEffects,
            CreateFolderNotificationEffects,
            CreateListingActivityNotificationEffects,
            CreateListingNotificationEffects,
            CreateUserNotificationEffects
        ])
    ],
    exports: [
        NotificationsComponent,
    ],
    providers: [{ provide: MAT_BOTTOM_SHEET_DATA, useValue: {} }]
})
export class NotificationsModule {
    constructor(
        private readonly translationLoaderService: TranslationLoaderService,
        private readonly serverMessageService: ServerMessageService
    ) {
        this.translationLoaderService.loadTranslations(english);
        this.serverMessageService.loadServerMessagesMapping(serverMessagesMapping);
    }
}