import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { MatDatepickerModule } from '@matheo/datepicker';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { PhoneFormInputComponent } from '@core-controls/components/phone-form-input/phone-form-input.component';
import { RpcButtonToggleComponent } from '@core-controls/components/rpc-button-toggle/rpc-button-toggle.component';
import { RpcButtonComponent } from '@core-controls/components/rpc-button/rpc-button.component';
import { RpcDatePickerComponent } from '@core-controls/components/rpc-date-picker/rpc-date-picker.component';
import { RpcIconButtonComponent } from '@core-controls/components/rpc-icon-button/rpc-icon-button.component';
import { RpcInputComponent } from '@core-controls/components/rpc-input/rpc-input.component';
import { RpcMenuButtonComponent } from '@core-controls/components/rpc-menu-button/rpc-menu-button.component';
import { RpcMenuIconButtonComponent } from '@core-controls/components/rpc-menu-icon-button/rpc-menu-icon-button.component';
import { RpcRangeSliderComponent } from '@core-controls/components/rpc-range-slider/rpc-range-slider.component';
import { RpcSelectComponent } from '@core-controls/components/rpc-select/rpc-select.component';
import { locale as english } from '@core-controls/i18n/en';
import { RpcDirectivesModule } from '@core-directives/directives.module';
import { TranslationLoaderService } from '@core-services/translation-loader.service';
import { RpcIconModule } from '@core-utils/rpc-icon/rpc-icon.module';
import { RpcCheckboxComponent } from './components/rpc-checkbox/rpc-checkbox.component';
import { RpcFileUploadComponent } from './components/rpc-file-upload/rpc-file-upload.component';
import { RpcIconFieldComponent } from './components/rpc-icon-field/rpc-icon-field.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { PasswordComponent } from './components/password/password.component';

const declaredExportModules = [
    PhoneFormInputComponent,
    RpcInputComponent,
    RpcButtonComponent,
    RpcIconButtonComponent,
    RpcMenuButtonComponent,
    RpcMenuIconButtonComponent,
    RpcButtonToggleComponent,
    RpcDatePickerComponent,
    RpcSelectComponent,
    RpcRangeSliderComponent,
    RpcFileUploadComponent,
    RpcCheckboxComponent,
    RpcIconFieldComponent,
    PasswordComponent
];

@NgModule({
    declarations: [
        declaredExportModules
    ],
    imports: [
        // Library Modules
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        NgxMatSelectSearchModule,
        MatButtonModule,
        MatInputModule,
        MatTooltipModule,
        RouterModule,
        MatMenuModule,
        MatButtonToggleModule,
        MatDatepickerModule,
        MatSliderModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatInputModule,

        // Application Modules
        RpcIconModule,
        RpcDirectivesModule
    ],
    exports: [
        declaredExportModules
    ]
})
export class RpcControlsModule {
    constructor(private readonly translationLoaderService: TranslationLoaderService) {
        this.translationLoaderService.loadTranslations(english);
    }
}