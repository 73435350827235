import { animate, AnimationBuilder, AnimationPlayer, style } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { HybridService } from '../hybrid/hybrid.service';

const DEFAULT_HIDE_SPEED = 400;

@Injectable({ providedIn: 'root' })
export class SplashScreenService {

    private static readonly SplashScreenElementId = '#splash-screen';
    private static readonly ForceHideDelayDelay = 4000;

    private splashScreenElement: HTMLElement;
    private animationPlayer: AnimationPlayer;

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly animationBuilder: AnimationBuilder,
        private readonly router: Router,
        private readonly hybridService: HybridService,
    ) { }

    public show(): void {
        this.animationPlayer = this.animationBuilder
            .build([
                style({ opacity: '0', zIndex: '99999' }),
                animate('400ms ease', style({ opacity: '1' }))
            ])
            .create(this.getSplashScreenElement());

        setTimeout(() => { this.animationPlayer.play(); });
    }

    public forceHide(speed: number = DEFAULT_HIDE_SPEED): void {
        this.animationPlayer = this.animationBuilder
            .build([
                style({ opacity: '1' }),
                animate(`${speed}ms ease`, style({ opacity: '0', zIndex: '-10' }))
            ]).create(this.getSplashScreenElement());

        setTimeout(() => { this.animationPlayer.play(); });
    }

    public hide(): void {
        if (this.document.readyState === 'loading') {
            this.document.onreadystatechange = () => {
                if (this.document.readyState === 'interactive') {
                    this.initializeSplashScreenHideEvent();
                }
            };
        } else {
            this.initializeSplashScreenHideEvent();
        }
    }

    private getSplashScreenElement(): HTMLElement {
        if (this.splashScreenElement != null) {
            return this.splashScreenElement;
        }

        this.splashScreenElement = this.document.body.querySelector(SplashScreenService.SplashScreenElementId);

        return this.splashScreenElement;
    }

    // TODO : Implemented in scope of RPC-463. Infinite splash screen should be deeply investigated to avoid force hide.
    private initializeSplashScreenHideEvent(): void {

        // This function trigger unnecessary splash screen on mobile devices that
        // looks like dark theme for a split second
        if (HybridService.isHybridApp()) {
            return;
        }

        let isSplashScreenHidden = false;
        const splashScreenElement = this.getSplashScreenElement();

        if (splashScreenElement != null) {
            const splashScreenHideTrigger = () => {
                if (!isSplashScreenHidden) {
                    isSplashScreenHidden = true;
                    this.forceHide();
                }
            };

            this.router.events
                .pipe(filter((event => event instanceof NavigationEnd)), take(1))
                .subscribe(() => setTimeout(splashScreenHideTrigger));

            setTimeout(splashScreenHideTrigger, SplashScreenService.ForceHideDelayDelay);
        }
    }
}