import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { InViewportModule } from '@thisissoon/angular-inviewport';

import { AppointmentFormComponent } from '@appointments/components/appointment-form/appointment-form.component';
import { AppointmentComponent } from '@appointments/components/appointment/appointment.component';
import { AppointmentsComponent } from '@appointments/components/appointments/appointments.component';
import { locale as english } from '@appointments/i18n/en';
import { AppointmentsEffects } from '@appointments/store/effects/appointments.effects';
import { appointmentsReducer } from '@appointments/store/reducers/appointments.reducer';
import { RpcControlsModule } from '@core-controls/controls.module';
import { RpcDirectivesModule } from '@core-directives/directives.module';
import { CustomSpinnerModule } from '@core-layout/custom-spinner/custom-spinner.module';
import { RpcPipesModule } from '@core-pipes/pipes.module';
import { TranslationLoaderService } from '@core-services/translation-loader.service';
import { RpcIconModule } from '@core-utils/rpc-icon/rpc-icon.module';
import { MediaModule } from '@media/media.module';
import { UsersModule } from '@users/users.module';

@NgModule({
    declarations: [
        AppointmentsComponent,
        AppointmentFormComponent,
        AppointmentComponent
    ],
    imports: [
        // Library Modules
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,

        InViewportModule,

        // Application Modules
        RpcDirectivesModule,
        RpcPipesModule,
        CustomSpinnerModule,
        RpcIconModule,
        RpcControlsModule,
        UsersModule,
        MediaModule,

        // Module Configuration
        StoreModule.forFeature('appointments', appointmentsReducer),
        EffectsModule.forFeature([AppointmentsEffects])
    ],
    exports: [
        AppointmentsComponent,
        AppointmentFormComponent,
        AppointmentComponent
    ],
    providers: []
})
export class AppointmentsModule {
    constructor(private readonly translationLoaderService: TranslationLoaderService) {
        this.translationLoaderService.loadTranslations(english);
    }
}