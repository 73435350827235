<form class="nm-settings" *ngIf="settings != null" name="newMatchSettingsForm" [formGroup]="newMatchSettingsForm">

    <div class="nm-settings-section">

        <div class="nm-settings-section-top">

            <div class="nm-settings-section-title">
                {{ 'NM.TITLES.CHECK_FOR_NEW_MATCHES' | translate }}
            </div>

            <div class="nm-settings-section-note">
                {{ 'NM.TITLES.HOW_OFTEN_SHOULD_WE_CHECK' | translate }}?
            </div>

        </div>

        <div class="nm-settings-section-btn-list">

            <rpc-button class="nm-settings-section-btn" [title]="'NM.TITLES.EVERY_FIVE_MIN' | translate"
                [appearanceType]="getCheckFrequencyButtonAppearance(NewMatchCheckingFrequency.realtime)"
                [color]="getCheckFrequencyButtonColor(NewMatchCheckingFrequency.realtime)"
                (clicked)="toggleCheckFrequency(NewMatchCheckingFrequency.realtime)">
            </rpc-button>

            <rpc-button class="nm-settings-section-btn" [title]="'NM.TITLES.FOUR_TIMES_A_DAY' | translate"
                [appearanceType]="getCheckFrequencyButtonAppearance(NewMatchCheckingFrequency.daily)"
                [color]="getCheckFrequencyButtonColor(NewMatchCheckingFrequency.daily)"
                (clicked)="toggleCheckFrequency(NewMatchCheckingFrequency.daily)">
            </rpc-button>

        </div>

    </div>

    <div class="rpc-divider rpc-divider--horizontal">
    </div>

    <div class="nm-settings-section">

        <div class="nm-settings-section-top">

            <div class="nm-settings-section-title">
                {{ 'NM.TITLES.EMAIL_TEMPLATE' | translate }}
            </div>

            <div class="nm-settings-section-note">
                {{ 'NM.TITLES.HOW_WOULD_YOU_LIKE_TO_FORMAT_INFORMATION' | translate }}?
            </div>

        </div>

        <div class="nm-settings-section-btn-list">

            <rpc-button class="nm-settings-section-btn nm-settings-section-btn--icon"
                [title]="'NM.TITLES.DETAILED_VIEW' | translate"
                [appearanceType]="getEmailTemplateButtonAppearance(NewMatchEmailTemplate.detail)" [iconName]="'master'"
                [color]="getEmailTemplateButtonColor(NewMatchEmailTemplate.detail)"
                (clicked)="toggleEmailTemplate(NewMatchEmailTemplate.detail)">
            </rpc-button>

            <rpc-button class="nm-settings-section-btn nm-settings-section-btn--icon"
                [title]="'NM.TITLES.SUMMARY_VIEW' | translate"
                [appearanceType]="getEmailTemplateButtonAppearance(NewMatchEmailTemplate.summary)"
                [iconName]="'summary-lines'" [color]="getEmailTemplateButtonColor(NewMatchEmailTemplate.summary)"
                (clicked)="toggleEmailTemplate(NewMatchEmailTemplate.summary)">
            </rpc-button>

        </div>

    </div>

    <div class="rpc-divider rpc-divider--horizontal">
    </div>

    <div class="nm-settings-section">

        <div class="nm-settings-section-top">

            <div class="nm-settings-section-title">
                {{ 'NM.TITLES.MY_NOTIFICATIONS' | translate }}
            </div>

            <div class="nm-settings-section-note">
                {{ 'NM.TITLES.HOW_OFTEN_TO_RECEIVE' | translate }}?
            </div>

        </div>

        <div class="nm-settings-section-btn-list">

            <rpc-button *ngFor="let option of notificationFrequencyOptions" class="nm-settings-section-btn"
                [title]="option.titleTranslationKey | translate"
                [appearanceType]="getEmailNotificationFrequencyButtonAppearance(option.frequency)"
                [color]="getEmailNotificationFrequencyButtonColor(option.frequency)"
                (clicked)="toggleEmailNotificationFrequency(option.frequency)">
            </rpc-button>

        </div>

    </div>

    <div class="nm-settings-section" *ngIf="isEmailNotificationFrequencySelected('realtime')">

        <div class="nm-settings-section-title">
            {{'NM.TITLES.DELIVERY_SCHEDULE' | translate}}
        </div>

        <div class="nm-settings-section-text">
            {{ 'NM.TEXT.REALTIME' | translate }}
        </div>

    </div>

    <div class="nm-settings-section" *ngIf="isEmailNotificationFrequencySelected('daily')">

        <div class="nm-settings-section-title">
            {{'NM.TITLES.DELIVERY_SCHEDULE' | translate}}
        </div>

        <div class="nm-settings-section-bnt-wrap">

            <div class="nm-settings-section-btn-list">

                <rpc-button *ngFor="let option of weekDayOptions" class="nm-settings-section-btn"
                    [title]="option.titleTranslationKey| translate"
                    [appearanceType]="getDailyFrequencyButtonAppearance(option.day)"
                    [color]="getDailyFrequencyButtonColor(option.day)" (clicked)="toggleDailyFrequencyDay(option.day)">
                </rpc-button>

            </div>

            <mat-error *ngIf="newMatchSettingsForm.controls?.selectedDays?.value.length === 0">
                {{ 'NM.ERROR_MESSAGE.SELECT_ONE_DAY' | translate }}
            </mat-error>

        </div>

        <div class="nm-settings-section-text">
            {{ 'NM.TEXT.EMAILS_ARE_SENT' | translate }}
            <rpc-select class="nm-settings-section-select nm-settings-section-select--sm" [color]="'accent'"
                [appearance]="'fill'" [controlOptions]="frequencyPerDay">
            </rpc-select>
            {{ 'NM.TEXT.PER_DAY' | translate }}
        </div>

    </div>

    <div class="nm-settings-section" *ngIf="isEmailNotificationFrequencySelected('weekly')">

        <div class="nm-settings-section-title">
            {{'NM.TITLES.DELIVERY_SCHEDULE' | translate}}
        </div>

        <div class="nm-settings-section-text">
            {{ 'NM.TEXT.EVERY' | translate }}
            <rpc-select class="nm-settings-section-select nm-settings-section-select--xs" [color]="'accent'"
                [appearance]="'fill'" [controlOptions]="weeklyFrequency">
            </rpc-select>
            {{ 'NM.TEXT.WEEKS' | translate }}
            <rpc-select class="nm-settings-section-select nm-settings-section-select--sm" [color]="'accent'"
                [appearance]="'fill'" [controlOptions]="weeklyFrequencyDay">
            </rpc-select>
        </div>

        <div class="nm-settings-section-text">
            {{ 'NM.TEXT.WEEKLY_EMAIL_SENDING' | translate }}
        </div>

    </div>

    <div class="nm-settings-section" *ngIf="isEmailNotificationFrequencySelected('monthly')">

        <div class="nm-settings-section-title">
            {{'NM.TITLES.DELIVERY_SCHEDULE' | translate}}
        </div>

        <div class="nm-settings-section-text">
            {{ 'NM.TEXT.EVERY' | translate }}
            <rpc-select class="nm-settings-section-select" [color]="'accent'" [appearance]="'fill'"
                [controlOptions]="monthlyFrequency">
            </rpc-select>
            {{ 'NM.TEXT.OF_THE_MONTH' | translate }}
        </div>

        <div class="nm-settings-section-text">
            {{ 'NM.TEXT.MONTHLY_EMAIL_SENDING' | translate }}
        </div>

    </div>

</form>