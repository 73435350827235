<div class="profile-general-info" [class.profile-general-info--child]="!isPrimaryCustomer" [formGroup]="form">

    <ng-container *ngIf="isPrimaryCustomer && isSelfProfile">

        <div class="profile-general-info-item">

            <div class="profile-general-info-item-title">
                {{ 'PROFILE.TITLES.EMAILS' | translate }}
            </div>

            <emails-edit-form class="profile-general-info-item-block" [parentGroup]="form"
                [controlName]="emailControlName" [emails]="emails">
            </emails-edit-form>

        </div>

        <div class="rpc-divider rpc-divider--horizontal profile-general-info-divider">
        </div>

    </ng-container>

    <div class="profile-general-info-item">

        <div class="profile-general-info-item-title">
            {{ 'PROFILE.TITLES.PHONES' | translate }}
        </div>

        <phones-edit-form class="profile-general-info-item-block" [parentGroup]="form" [controlName]="phoneControlName"
            [phones]="phones">
        </phones-edit-form>

    </div>

    <div class="rpc-divider rpc-divider--horizontal profile-general-info-divider">
    </div>

    <div class="profile-general-info-item">

        <div class="profile-general-info-item-title">
            {{ 'PROFILE.TITLES.ADDRESSES' | translate }}
        </div>

        <addresses-edit-form class="profile-general-info-item-block" [parentGroup]="form"
            [controlName]="addressControlName" [addresses]="addresses">
        </addresses-edit-form>

    </div>

</div>