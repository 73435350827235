import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { NgLetModule } from 'ng-let';

import { AgentsModule } from '@agents/agents.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AuthRoutingModule } from '@auth/auth-routing.module';
import { RegistrationFormComponent } from '@auth/components/registration-form/registration-form.component';
import { locale as english } from '@auth/i18n/en';
import { ForgotPasswordComponent } from '@auth/pages/forgot-password/forgot-password.component';
import { LoginComponent } from '@auth/pages/login/login.component';
import { RegistrationComponent } from '@auth/pages/registration/registration.component';
import { RestorePasswordComponent } from '@auth/pages/restore-password/restore-password.component';
import { serverMessagesMapping } from '@auth/server-message-mapping/server-message-mapping';
import { AuthEffects } from '@auth/store/effects/auth.effects';
import { RestorePasswordEffects } from '@auth/store/effects/restore-password.effects';
import { authReducer } from '@auth/store/reducers/auth.reducer';
import { RpcControlsModule } from '@core-controls/controls.module';
import { RpcDirectivesModule } from '@core-directives/directives.module';
import { ScrollToTopModule } from '@core-layout/scroll-to-top/scroll-to-top.module';
import { ServerMessageService } from '@core-services/server-message.service';
import { TranslationLoaderService } from '@core-services/translation-loader.service';
import { RpcIconModule } from '@core-utils/rpc-icon/rpc-icon.module';
import { UsersModule } from '@users/users.module';
import { SwitchCollaborationSpaceEffects } from './store/effects/switch-collaboration-space.effects';
import { AdditionalFooterModule } from '@core-layout/additional-footer/additional-footer.module';
import { NotAuthorizedToolbarModule } from '@core-layout/not-authorized-toolbar/not-authorized-toolbar.module';

@NgModule({
    declarations: [
        LoginComponent,
        RegistrationComponent,
        RegistrationFormComponent,
        RestorePasswordComponent,
        ForgotPasswordComponent
    ],
    imports: [
        // Library Modules
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,

        MaterialFileInputModule,
        MatInputModule,
        MatBottomSheetModule,
        MatButtonModule,
        FlexLayoutModule,
        NgLetModule,

        // Application Modules
        RpcDirectivesModule,
        AgentsModule,
        RpcIconModule,
        UsersModule,
        RpcControlsModule,
        ScrollToTopModule,
        AdditionalFooterModule,
        NotAuthorizedToolbarModule,

        // Module Configuration
        AuthRoutingModule,
        StoreModule.forFeature('auth', authReducer),
        EffectsModule.forFeature([AuthEffects, RestorePasswordEffects, SwitchCollaborationSpaceEffects])
    ],
    exports: []
})
export class AuthModule {
    constructor(
        private readonly translationLoaderService: TranslationLoaderService,
        private readonly serverMessageService: ServerMessageService
    ) {
        this.translationLoaderService.loadTranslations(english);
        this.serverMessageService.loadServerMessagesMapping(serverMessagesMapping);
    }
}