<div class="folder-menu" *ngIf="folder != null" [class.folder-menu--system]="folder.isSystemFolder"
    [class.folder-menu--header]="hasClose || showFolderName">

    <div class="folder-menu-header" *ngIf="hasClose || showFolderName" [class.folder-menu-header--close]="hasClose"
        [class.folder-menu-header--title]="showFolderName">

        <div class="folder-menu-header-title" *ngIf="showFolderName"
            [title]="folder.isSystemFolder ? (('FOLDER_DETAILS.TITLES.' + folder.name) | translate) : folder.name">
            {{ folder.isSystemFolder ? (('FOLDER_DETAILS.TITLES.' + folder.name) | translate) : folder.name }}
        </div>

        <rpc-icon-button class="folder-menu-header-close" *ngIf="hasClose" [iconName]="'cross'"
            [title]="'FOLDER_DETAILS.TITLES.CLOSE'" (clicked)="onClose()">
        </rpc-icon-button>

    </div>

    <div class="folder-menu-body">

        <ng-container *ngIf="!folder.isSystemFolder && folder.creatorInfo != null">

            <div class="folder-creator">

                <avatar-initials class="folder-creator-avatar" [userFullName]="folder.creatorInfo?.fullName"
                    [userProfileImageUrl]="folder.creatorInfo?.profileImage">
                </avatar-initials>

                <div class="folder-creator-name text-truncate">
                    {{ folder.creatorInfo?.fullName }}
                </div>

                <div class="folder-creator-info">

                    <div>
                        {{ 'FOLDER_DETAILS.TITLES.FOLDER_CREATOR' | translate }}
                    </div>

                    <div class="rpc-divider rpc-divider--vertical folder-creator-info-divider">
                    </div>

                    <div>
                        {{ folder?.createDateTime | date: DateConstants.Formats.AngularPipeShortDateSlash }}
                    </div>

                </div>

            </div>

            <div class="rpc-divider rpc-divider--horizontal">
            </div>

        </ng-container>

        <ng-container *ngIf="!folder.isSystemFolder && savedSearchesNames.length !== 0">

            <div class="folder-saved-search" [class.folder-saved-search--opened]="isSavedSearchesAreaExpanded"
                [class.folder-saved-search--clickable]="hasMultipleSavedSearches" (click)="onSavedSearchesCliked()">

                <rpc-icon class="folder-saved-search-icon" [iconName]="'saved-search'">
                </rpc-icon>

                <div class="folder-saved-search-block">

                    <div class="folder-saved-search-title">
                        {{ 'FOLDER_DETAILS.TITLES.ATTACHED_SAVED_SEARCH' | translate }}
                    </div>

                    <ng-container *ngIf="!hasMultipleSavedSearches; else multipleSavedSearches">

                        <div class="folder-saved-search-name">
                            {{ savedSearchesNames[0] }}
                        </div>

                    </ng-container>

                    <ng-template #multipleSavedSearches>

                        <div class="folder-saved-search-name-wrap">

                            <div class="folder-saved-search-name-block">

                                <div class="folder-saved-search-name"
                                    *ngFor="let savedSearchName of savedSearchesNames">
                                    {{ savedSearchName }}
                                </div>

                            </div>

                        </div>

                    </ng-template>

                    <div class="folder-saved-search-trigger" *ngIf="hasMultipleSavedSearches">

                        <div class="folder-saved-search-trigger-text">
                            {{ (isSavedSearchesAreaExpanded ? 'FOLDER_DETAILS.TITLES.HIDE' :
                            'FOLDER_DETAILS.TITLES.SEE_ALL') | translate }}
                        </div>

                        <rpc-icon class="folder-saved-search-trigger-icon" [iconName]="'arrow-down'">
                        </rpc-icon>

                    </div>

                </div>

            </div>

            <div class="rpc-divider rpc-divider--horizontal">
            </div>

        </ng-container>

        <mat-slide-toggle class="folder-default-toggle" [labelPosition]="'before'" [checked]="isDefault"
            (change)="onDefaultFolderChange($event.checked)">

            <span class="folder-default-toggle-box">

                <span class="folder-default-toggle-text">
                    {{ 'FOLDER_DETAILS.TITLES.SET_DEFAULT' | translate }}
                </span>

                <span class="folder-default-toggle-note">
                    {{ 'FOLDER_DETAILS.TITLES.DEFAULT_FOLDER_MESSAGE' | translate}}
                </span>

            </span>

        </mat-slide-toggle>

        <ng-container *ngIf="!folder.isSystemFolder">

            <div class="rpc-divider rpc-divider--horizontal">
            </div>

            <div class="folder-menu-footer">

                <rpc-button class="folder-menu-footer-item" [iconName]="'edit'" [title]="'FOLDER_DETAILS.TITLES.EDIT'"
                    (clicked)="edit()">
                </rpc-button>

                <rpc-button class="folder-menu-footer-item" [iconName]="'delete-bin'"
                    [title]="'FOLDER_DETAILS.TITLES.DELETE_FOLDER'" (clicked)="onDeleteFolder()">
                </rpc-button>

            </div>

        </ng-container>

    </div>

</div>