import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';

import { CheckboxFilterComponent } from './components/checkbox-filter/checkbox-filter.component';
import { OmniSearchComponent } from './components/omni-search/omni-search.component';
import { RangeFilterComponent } from './components/range-filter/range-filter.component';
import { RpcControlsModule } from '@core-controls/controls.module';
import { locale as english } from './i18n/en';
import { TranslationLoaderService } from '@core-services/translation-loader.service';
import { RpcIconModule } from '@core-utils/rpc-icon/rpc-icon.module';
import { RpcDirectivesModule } from '@core-directives/directives.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PatternSearchEffects } from './store/effects/pattern-search.effects';
import { searchReducer } from './store/reducers/search.reducer';
import { SearchCriteriaEffects } from './store/effects/search-criteria.effects';

const components = [
    RangeFilterComponent,
    CheckboxFilterComponent,
    OmniSearchComponent,
];

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        MatSlideToggleModule,
        MatChipsModule,
        MatAutocompleteModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,

        RpcDirectivesModule,
        RpcControlsModule,
        RpcIconModule,

        // Module Configuration
        StoreModule.forFeature('search', searchReducer),
        EffectsModule.forFeature([PatternSearchEffects, SearchCriteriaEffects])
    ],
    declarations: components,
    exports: components
})
export class SearchModule {
    constructor(private readonly translationLoaderService: TranslationLoaderService) {
        this.translationLoaderService.loadTranslations(english);
    }
}