<div class="nm-short-info-wrap" *ngIf="listingActivityContribution!=null">

    <ng-container
        *ngFor="let newMatch of listingActivityContribution.newMatches; trackBy: trackByNewMatchId; let $index = index;">

        <div class="rpc-divider rpc-divider--horizontal" *ngIf="$index > 0">
        </div>

        <div class="nm-short-info">

            <div class="nm-short-info-title" *ngIf="listingActivityContribution.newMatches.length > 1">
                {{'NEW_MATCH_TOOLTIP.NEW_MATCH' | translate}} {{$index + 1}}</div>

            <div>
                <div class="nm-short-info-label">{{'NEW_MATCH_TOOLTIP.DATE' | translate}}</div>
                <div class="nm-short-info-text" [title]="newMatch.updateDate | date:
                dateConstants.Formats.AngularPipeShortDateSlash">{{ newMatch.updateDate | date:
                    dateConstants.Formats.AngularPipeShortDateSlash }}</div>
            </div>

            <div>
                <div class="nm-short-info-label">{{'NEW_MATCH_TOOLTIP.SAVED_SEARCH' | translate}}</div>
                <div class="nm-short-info-text" [title]="newMatch.isAgentNewMatch
                        ? ('NEW_MATCH_TOOLTIP.AGENTS_SAVED_SEARCH' | translate : {ownerFullName : newMatch.creator.fullName})
                        : newMatch.savedSearchName">
                    {{ newMatch.isAgentNewMatch
                    ? ('NEW_MATCH_TOOLTIP.AGENTS_SAVED_SEARCH' | translate : {ownerFullName :
                    newMatch.creator.fullName})
                    : newMatch.savedSearchName}}
                </div>
            </div>

            <div *ngIf="newMatch.folderName != null">
                <div class="nm-short-info-label">{{'NEW_MATCH_TOOLTIP.FOLDER' | translate}}</div>
                <div class="nm-short-info-folder" [title]="newMatch.folderName">
                    <rpc-icon class="nm-short-info-folder-icon" [iconName]="'folder'"></rpc-icon>
                    <div class="nm-short-info-folder-text">{{newMatch.folderName}}</div>
                </div>
            </div>

        </div>
    </ng-container>
</div>