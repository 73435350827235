<rpc-icon-button class="rpc-activity-button" [class]="{
        'liked': calculatedActivities?.isLiked,
        'disliked': calculatedActivities?.isDisliked,
        'shown': calculatedActivities?.isShown && !calculatedActivities?.isLiked && !calculatedActivities?.isDisliked
        }" [iconName]="iconName" [actionClass]="'active ' + activityClass"
    [actionTrigger]="calculatedActivities?.isLiked || calculatedActivities?.isDisliked || calculatedActivities?.isShown"
    [badgeIcon]="badgeIcon" [badgeIconName]="'eye'" [enableCustomBackground]="true" [disabled]="disabled" rpcTooltip
    [customClass]="'tooltip-list--inline-padding-empty'" [component]="tooltipComponent"
    [trigger]="isMobile ? 'longTap' : 'hover'" [componentScopeData]="tooltipData" [showToolTip]="!disabled"
    (tooltipInitialized)="tooltipInitialized($event)" (clicked)="onClick()">
</rpc-icon-button>