<div class="listing-labels-wrap">

    <ng-container *ngIf="openHouses?.length > 0">
        <div class="listing-labels-item oh" [class.listing-labels-item--text]="isLongLabels" rpcTooltip
            #tooltip="rpcTooltip" [customClass]="'rpc-oh-tooltip-modal'" [trigger]="isMobile ? 'singleClick' : 'hover'"
            [component]="OpenHouseTooltipComponent" [componentScopeData]="openHouseTooltipData">

            <rpc-icon class="listing-labels-item-icon" [iconName]="'oh'">
            </rpc-icon>

            <div *ngIf="isLongLabels" class="listing-labels-item-text">
                {{ 'LISTING_LABELS.OPEN_HOUSE.LONG_TITLE_WITH_DATE' | translate: {date: (openHouses[0].startDate | date:
                DateConstants.Formats.DayAndMonth)} }}
            </div>

        </div>

    </ng-container>

    <ng-container *ngIf="isNewMatch">

        <div class="listing-labels-item nm" [class.listing-labels-item--text]="isLongLabels" rpcTooltip
            #tooltip="rpcTooltip" [customClass]="'rpc-nm-short-tooltip'" [trigger]="isMobile ? 'singleClick' : 'hover'"
            [component]="NewMatchTooltipComponent" [componentScopeData]="newMatchTooltipData">

            <rpc-icon class="listing-labels-item-icon" [iconName]="'star'">
            </rpc-icon>

            <div *ngIf="isLongLabels" class="listing-labels-item-text">
                {{ 'LISTING_LABELS.NEW_MATCH.LONG_TITLE' | translate }}
            </div>

        </div>

    </ng-container>

    <ng-container *ngIf="showNewlyAddedTag && !isNewViewed">

        <div class="listing-labels-item na" [class.listing-labels-item--text]="isLongLabels"
            [title]="'LISTING_LABELS.NEWLY_ADDED_TO_PORTFOLIO.LONG_TITLE' | translate">

            <rpc-icon class="listing-labels-item-icon" [iconName]="'add-new'">
            </rpc-icon>

            <div *ngIf="isLongLabels" class="listing-labels-item-text">
                {{ 'LISTING_LABELS.NEWLY_ADDED_TO_PORTFOLIO.LONG_TITLE' | translate }}
            </div>

        </div>

    </ng-container>

    <ng-container *ngIf="priceChange?.type === decreasePriceChangeType">

        <div class="listing-labels-item price-drop" [class.listing-labels-item--text]="isLongLabels" rpcTooltip
            #tooltip="rpcTooltip" [customClass]="'rpc-pc-short-tooltip'" [trigger]="isMobile ? 'singleClick' : 'hover'"
            [component]="PriceChangeInfoComponent" [componentScopeData]="priceChangeInfoData">

            <rpc-icon class="listing-labels-item-icon" [iconName]="'down'">
            </rpc-icon>

            <div *ngIf="isLongLabels" class="listing-labels-item-text">
                {{ 'LISTING_LABELS.PRICE_DROP.LONG_TITLE' | translate }}
            </div>

        </div>

    </ng-container>

</div>