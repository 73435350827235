/* eslint-disable @typescript-eslint/no-magic-numbers */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
import debounce from 'lodash-es/debounce';

export function Debounce(delay = 300): MethodDecorator {
    return function (target: object, propertyKey: string | symbol, descriptor: TypedPropertyDescriptor<any>): TypedPropertyDescriptor<any> | void {

        const map = new WeakMap();

        const original = descriptor.value;

        descriptor.value = function (...args: any[]) {
            let debounced = map.get(this);

            if (!debounced) {
                debounced = debounce(original, delay).bind(this);
                map.set(this, debounced);
            }

            debounced(...args);
        };

        return descriptor;
    };
}