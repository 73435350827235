import { AgentsModule } from '@agents/agents.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgLetModule } from 'ng-let';

import { RpcControlsModule } from '@core-controls/controls.module';
import { locale as english } from '@core-layout/toolbar/i18n/en';
import { ToolbarComponent } from '@core-layout/toolbar/toolbar.component';
import { TranslationLoaderService } from '@core-services/translation-loader.service';
import { RpcIconModule } from '@core-utils/rpc-icon/rpc-icon.module';
import { NotificationsModule } from '@notifications/notifications.module';
import { UsersModule } from '@users/users.module';
import { ProfileBaseModule } from 'app/modules/user-modules/profile-modules/profile-base/profile-base.module';
import { SavedSearchModule } from '@saved-search/saved-search.module';
import { LogoModule } from '@core-layout/logo/logo.module';

@NgModule({
    declarations: [
        ToolbarComponent
    ],
    imports: [
        // Library Modules
        CommonModule,
        TranslateModule,
        MatMenuModule,
        FlexLayoutModule,
        MatButtonModule,
        RouterModule,
        MatRippleModule,
        MatBottomSheetModule,
        NgLetModule,

        // Application Modules
        AgentsModule,
        RpcIconModule,
        UsersModule,
        RpcControlsModule,
        NotificationsModule,
        ProfileBaseModule,
        SavedSearchModule,
        LogoModule
    ],
    exports: [
        ToolbarComponent
    ]
})
export class ToolbarModule {
    constructor(private readonly translationLoaderService: TranslationLoaderService) {
        this.translationLoaderService.loadTranslations(english);
    }
}